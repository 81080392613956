import React, { useState } from 'react'

import { industriesConfig } from '../constants/industries'
import IndustryItem from '../components/IndastriesComponents/IndustryItem'
import TitleBlock from '../components/IndastriesComponents/TitleBlock'
import Modal from '../components/NormalModal'

import './Industries.scss'

export const Industries = () => {
  const [modalActive, setModalActive] = useState(false)
  const [list, setList] = useState([])

  const industriesBlocks = industriesConfig.map(({ name, img, listLength }) => (
    <IndustryItem
      key={name}
      name={name}
      img={img}
      listLength={listLength}
      setModalActive={setModalActive}
      setList={setList}
    />
  ))

  return (
    <div className="Industries">
      <TitleBlock />
      <div className="industies-block">{industriesBlocks}</div>
      <Modal active={modalActive} setActive={setModalActive}>
        <ul>{list}</ul>
      </Modal>
    </div>
  )
}
