import React from 'react'
// eslint-disable-next-line
import { t } from 'i18next'
import { Link } from 'react-router-dom'

import logo from '../../assets/images/anvarix-logo.png'
import telegram from '../../assets/images/icons/telegram.svg'
import instagram from '../../assets/images/icons/instagram.svg'
import facebook from '../../assets/images/icons/facebook.svg'
import Navigation from '../Navigation'

import './Footer.scss'

export default function Footer() {
  return (
    <div className="Footer-wrap">
      <div className="Footer">
        <div className="info-footer">
          <div className="logo-wrap">
            <Link to="/">
              <img src={logo} alt="logo" />
              <h3>Anvarix</h3>
            </Link>
          </div>
          <p>{t('nav.description')}</p>
        </div>
        <div className="nav-footer">
          <Navigation />
        </div>
        <div className="contact-footer">
          <div className="social-links-icons">
            <div>
              <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={telegram} alt="twitter icon" />
              </a>
            </div>
            <div>
              <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="linkedin icon" />
              </a>
            </div>
            <div>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-underline">
        <p>© 2023-2024 | Anvarix LLC. All rights reserved</p>
      </div>
    </div>
  )
}
