import React from 'react'
import { useTranslation } from 'react-i18next'

import { TbTransformFilled } from "react-icons/tb";
import { GrAnalytics } from "react-icons/gr";
import { BsClipboard2Data } from "react-icons/bs";
import { HiComputerDesktop } from "react-icons/hi2";

const HelpBlock = () => {
  const { t } = useTranslation()

  return (
      <div className="how-we-can-help-wrap">
        <div className="how-we-can-help">
          <div className="title-block">
            <h2>{t('page.home.how_we_can_help.title')}</h2>
          </div>
          <ul>
            <li>
              <TbTransformFilled className='icon'/>
              <div>
                <p className='list'>{t('page.home.how_we_can_help.list1')}</p>
                <p className='text'>{t('page.home.how_we_can_help.text1')}</p>
              </div>
            </li>
            <li>
              <GrAnalytics className='icon'/>
              <div>
                <p className='list'>{t('page.home.how_we_can_help.list2')}</p>
                <p className='text'>{t('page.home.how_we_can_help.text2')}</p>
              </div>
            </li>
            <li>
              <HiComputerDesktop className='icon'/>
              <div>
                <p className='list'>{t('page.home.how_we_can_help.list3')}</p>
                <p className='text'>{t('page.home.how_we_can_help.text3')}</p>
              </div>
            </li>
            <li>
              <BsClipboard2Data className='icon'/>
              <div>
                <p className='list'>{t('page.home.how_we_can_help.list4')}</p>
                <p className='text'>{t('page.home.how_we_can_help.text4')}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
  )
}

export default HelpBlock