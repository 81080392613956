import React from 'react'
import { useTranslation } from 'react-i18next'

const AboutBlock = () => {
  const { t } = useTranslation()

  const aboutList = []
  for (let i = 1; i <= 4; i++) {
    aboutList.push(
        <div key={i}>
          <p className="about-title">{t(`page.home.about_us.title${i}`)}</p>
          <p className="about-text">{t(`page.home.about_us.text${i}`)}</p>
        </div>,
    )
  }

  return (
    <div className="about-block-wrap">
      <div className="about-block">
        <h2>{t('page.home.about_us.title')}</h2>
        <h3 className="about-question">
          {t('page.home.about_us.description')}
        </h3>
        <div className="about-p">
          {aboutList}
        </div>
      </div>
    </div>
  )
}

export default AboutBlock