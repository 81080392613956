import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import {techConfig} from "../../../constants/tech";

const TechBlock = () => {
  const { t } = useTranslation()

  const [activeIndex, setActiveIndex] = useState(0)
  const btnList = techConfig.map(({ label }, index) => {
    const handleClick = () => {
      setActiveIndex(index)
    }

    const className = `tech-btn ${activeIndex === index ? 'active' : ''}`
    return (
        <button key={label} className={className} onClick={handleClick}>{label}</button>
    )
  })

  const techList = techConfig[activeIndex].img.map((src) => {
    return (
        <div key={src} className="tech-card">
          <img src={src} alt="tech-icon" />
        </div>
    )
  })

  return (
    <div className="tech-wrap">
      <div className="tech">
        <div className="title-block">
          <h2>{t('page.home.tech.title')}</h2>
        </div>
        <div className='tech-content'>
          <div className='btn-list'>
            {btnList}
          </div>
          <h3>{techConfig[activeIndex].name}</h3>
          <div className="tech-list">
            {techList}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechBlock
