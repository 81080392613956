import java from '../assets/images/tech-icons/java.svg'
import python from '../assets/images/tech-icons/python.svg'
import csharp from '../assets/images/tech-icons/CSharpLogo.svg'
import cplusplus from '../assets/images/tech-icons/cplusplus.svg'
import swift from '../assets/images/tech-icons/swift.svg'
import objectivec from '../assets/images/tech-icons/objectivec-plain.svg'
import javascript from '../assets/images/tech-icons/javascript.svg'
import typescript from '../assets/images/tech-icons/typescript.svg'
import android from '../assets/images/tech-icons/android.svg'
import apple from '../assets/images/tech-icons/apple_logo_black.svg'
import xamarin from '../assets/images/tech-icons/xamarin.svg'
import flutter from '../assets/images/tech-icons/flutter.svg'
import native from '../assets/images/tech-icons/react-native.svg'
import react from '../assets/images/tech-icons/react.svg'
import vue from '../assets/images/tech-icons/vue.svg'
import angularjs from '../assets/images/tech-icons/angularjs.svg'
import polymer from '../assets/images/tech-icons/polymer.svg'
import windows from '../assets/images/tech-icons/windows8-original.svg'
import osx from '../assets/images/tech-icons/osX.svg'
import linux from '../assets/images/tech-icons/linux.svg'
import teamcity from '../assets/images/tech-icons/teamcity.svg'
import jenkins from '../assets/images/tech-icons/jenkins.svg'
import gitlab from '../assets/images/tech-icons/gitlab.svg'
import bitbucket from '../assets/images/tech-icons/bitbucket-pipeline.svg'
import bamboo from '../assets/images/tech-icons/bamboo.svg'
import azure from '../assets/images/tech-icons/azure-devops.svg'

export const techConfig = [
  {
    label: 'PL',
    name: 'Programming Languages',
    img: [java, python, csharp, cplusplus, swift, objectivec, javascript, typescript]
  },
  {
    label: 'Mobile',
    name: 'Mobile',
    img: [android, apple, xamarin, flutter, native]
  },
  {
    label: 'Web',
    name: 'Web',
    img: [react, vue, angularjs, polymer, javascript, typescript]
  },
  {
    label: 'OS',
    name: 'Operating systems',
    img: [windows, osx, linux, android, apple]
  },
  {
    label: 'CI/CD',
    name: 'CI/CD',
    img: [teamcity, jenkins, gitlab, bitbucket, bamboo, azure]
  },
]
