import pics1 from '../assets/images/industry-tabs/118.svg'
import pics2 from '../assets/images/industry-tabs/102.svg'
import pics3 from '../assets/images/industry-tabs/120.svg'
import pics4 from '../assets/images/industry-tabs/121.svg'
import pics5 from '../assets/images/industry-tabs/122.svg'
import pics6 from '../assets/images/industry-tabs/125.svg'
import pics7 from '../assets/images/industry-tabs/123.svg'
import pics8 from '../assets/images/industry-tabs/124.svg'

export const industriesConfig = [
  {
    name: 'fintech',
    listLength: 2,
    img: pics1
  },
  {
    name: 'life_science',
    listLength: 6,
    img: pics2
  },
  {
    name: 'security',
    listLength: 4,
    img: pics3
  },
  {
    name: 'transport',
    listLength: 4,
    img: pics4
  },
  {
    name: 'blockchain',
    listLength: 2,
    img: pics5
  },
  {
    name: 'data_science',
    listLength: 2,
    img: pics6
  },
  {
    name: 'multimedia',
    listLength: 2,
    img: pics7
  },
  {
    name: 'artificial_intelligence',
    listLength: 2,
    img: pics8
  },
]
