import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Layout from './components/Layout'
import { Home } from './pages/Home'
import { Industries } from './pages/Industries'
import { Contacts } from './pages/Contacts'

import './App.scss'

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="industries" element={<Industries />} />
        <Route path="contacts" element={<Contacts />} />
      </Routes>
    </Layout>
  )
}

export default App
