import { observable, action } from 'mobx'
// eslint-disable-next-line
import i18next from 'i18next'
// import { addLocale, localeOptions, locale } from 'primereact/api'

class LanguageStore {
  @observable language = i18next.language

  constructor() {
    i18next.on('initialized', () => {
      // this.setPrimereactLocale(this.language)
    })

    i18next.on('languageChanged', (lng) => {
      // this.setPrimereactLocale(lng)
      this.language = lng
    })
  }

  @action
  changeLanguage = async (language) => {
    if (language === this.language) return

    await Promise.all([i18next.changeLanguage(language)])
  }

  // setPrimereactLocale = (lng) => {
  //   if (!localeOptions(lng)) {
  //     addLocale(lng, i18next.store.data[lng].translations.primereact)
  //   }
  //
  //   locale(lng)
  // }
}

export default new LanguageStore()
