import React from 'react'

import TitleBlock from '../components/HomeComponents/TitleBlock'
import AboutBlock from '../components/HomeComponents/AboutBlock'
import HelpBlock from '../components/HomeComponents/HelpBlock'
import ServicesBlock from '../components/HomeComponents/ServicesBlock'

import './Home.scss'
import TechBlock from '../components/HomeComponents/TechBlock'

export const Home = () => {
  return (
    <div className="home">
      <TitleBlock />
      <AboutBlock />
      <HelpBlock />
      <ServicesBlock />
      <TechBlock />
    </div>
  )
}
