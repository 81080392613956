import React from 'react'
import { useTranslation } from 'react-i18next'

const TitleBlock = () => {
  const { t } = useTranslation()

  return (
    <div className="Industries-wrap">
      <div className="Industries-we-offer">
          <div>
              <div className="title-block">
                  <h4>{t('nav.industries')}</h4>
              </div>
              <p className="Industries-par">{t('page.industries.description')}</p>
          </div>
        <div className='test'/>
      </div>
    </div>
  )
}

export default TitleBlock
