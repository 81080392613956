import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TbArrowBigRightFilled } from 'react-icons/tb'
import { TbArrowBigLeftFilled } from 'react-icons/tb'

import ServiceItem from './ServiceItem'

const ServicesBlock = () => {
  const { t } = useTranslation()

  const [activeIndex, setActiveIndex] = useState(1)
  const handleClick = (e, index) => {
    setActiveIndex(index)
  }

  const incrementClick = () => {
    if (activeIndex >= 1 && activeIndex < 5) {
      setActiveIndex((prev) => prev + 1)
    }
  }

  const decrementClick = () => {
    if (activeIndex > 1 && activeIndex <= 5) {
      setActiveIndex((prev) => prev - 1)
    }
  }

  const serviceList = []
  for (let i = 1; i <= 5; i++) {
    const active = activeIndex === i
    serviceList.push(
      <ServiceItem
        key={i}
        index={i}
        active={active}
        handleClick={handleClick}
      />,
    )
  }

  return (
    <div className="servises-we-offer-wrap">
      <div className="servises-we-offer">
        <div className="title-block">
          <h2>{t('page.home.services.title')}</h2>
        </div>
        <div className="content-block">
          <ul>{serviceList}</ul>
          <div className="description">
            <div>
              <p className="list">{t(`page.home.services.list${activeIndex}`)}</p>
              <p className="text">{t(`page.home.services.text${activeIndex}`)}</p>
            </div>
            <div className="arrows">
              <TbArrowBigLeftFilled
                className={`icon ${activeIndex === 1 ? 'disable' : ''}`}
                onClick={decrementClick}
              />
              <span>{activeIndex}</span>
              <TbArrowBigRightFilled
                className={`icon ${activeIndex === 5 ? 'disable' : ''}`}
                onClick={incrementClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesBlock
