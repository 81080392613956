import React from 'react'
import { useTranslation } from 'react-i18next'

import { navConfig } from '../../constants/navigation'
import { NavLink } from 'react-router-dom'

const Navigation = ({ mobVersion = false }) => {
  const { t } = useTranslation()
  const navList = navConfig.map(({ link, label }) => (
    <li key={label}>
      <NavLink to={link}>{t(`nav.${label}`)}</NavLink>
    </li>
  ))
  const className = mobVersion ? 'mobile-menu' : 'menu'
  return <ul className={className}>{navList}</ul>
}

export default Navigation
