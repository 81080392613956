import React from 'react'
import { inject, observer } from 'mobx-react'

import { selectionLng } from '../../../constants/languages'

const LngSwitcher = ({ changeLanguage, mobVersion = false }) => {
  const lngList = selectionLng.map(({ value, label }) => (
    <button
      key={value}
      className="lang-btn"
      onClick={() => changeLanguage(value)}
    >
      {label}
    </button>
  ))
  const className = mobVersion ? 'header-lang-mob' : 'header-language'
  return <div className={className}>{lngList}</div>
}

const withData = inject((stores) => ({
  language: stores.LanguageStore.language,
  changeLanguage: stores.LanguageStore.changeLanguage,
}))

export default withData(observer(LngSwitcher))
