import React from 'react'
import { useTranslation } from 'react-i18next'

import { MdKeyboardDoubleArrowRight } from 'react-icons/md'

const IndustryItem = ({ name, listLength, img, setModalActive, setList }) => {
  const { t } = useTranslation()
  const blockClassname = `industies-item ${name}`

  const list = []
  for (let i = 1; i <= listLength; i++) {
    const className = i % 2 === 0 ? 'project-desc' : 'project-title'
    list.push(
      <li key={i} className={className}>{t(`page.industries.${name}.list${i}`)}</li>,
    )
  }

  const handleClick = () => {
    setList(list)
    setModalActive(true)
  }

  return (
    <div className={blockClassname} onClick={handleClick}>
      <div className="industies-title">
        <h5>{t(`page.industries.${name}.title`)}</h5>
        <img src={img} alt={name} />
      </div>
      <div className="text">
        <p>{t(`page.industries.${name}.description`)}</p>
        <div className='explore-arrow'>
          <span>{t(`page.industries.explore`)}</span>
          <MdKeyboardDoubleArrowRight />
        </div>
      </div>
    </div>
  )
}

export default IndustryItem
