import React from 'react'

import phone from '../../../assets/images/icons/phone.svg'
import global from '../../../assets/images/icons/global.svg'
import location from '../../../assets/images/icons/location.svg'

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <h3>Anvarix</h3>
      <div className="info-subsection">
        <img src={phone} alt="icon" />
        <div>
          <a href="tel:+393311919797">+393311919797</a>
          <a href="tel:+380632072957">+380632072957</a>
        </div>
      </div>
      <div className="info-subsection">
        <img src={global} alt="icon" />
        <a href="mailto: office@anvarix.com">office@anvarix.com</a>
      </div>
      <div className="info-subsection">
        <img src={location} alt="icon" />
        <p className="address">Lesi Ukrainky Blvd, 3, Kyiv</p>
      </div>
    </div>
  )
}

export default ContactInfo
