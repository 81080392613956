import { observable, action } from 'mobx'
import { axiosInstance } from '../../../configs/axios.config'

import { API } from '../../../configs/api.config'

class ContactStore {
  @observable isSending = false

  @action
  sendEmail = async (data) => {
    this.isSending = true
    try {
      await axiosInstance.post(`${API}/contact`, {
        ...data,
      })
    } catch (e) {
      console.log(e.message)
    }
    this.isSending = false
  }
}

export default new ContactStore()