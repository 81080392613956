import React from 'react'
import { useTranslation } from 'react-i18next'

import { SlMinus } from 'react-icons/sl'
import { SlPlus } from 'react-icons/sl'

const ServiceItem = ({ active, index, handleClick }) => {
  const { t } = useTranslation()
  const icon = active ? <SlMinus className='icon' /> : <SlPlus className='icon' />

  return (
    <li onClick={(e) => handleClick(e, index)}>
      <div>
        {icon}
        <p className="list">{t(`page.home.services.list${index}`)}</p>
      </div>
    </li>
  )
}

export default ServiceItem
