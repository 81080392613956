import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'

import App from './App'
import initI18n from './i18n'
import stores from './stores'
import './assets/styles/index.scss'

void initI18n()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)
