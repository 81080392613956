import React from 'react'
import { useTranslation } from 'react-i18next'

import ContactForm from '../components/ContactComponents/ContactForm'
import ContactInfo from '../components/ContactComponents/ContactInfo'

import './Contacts.scss'

export const Contacts = () => {
  const { t } = useTranslation()

  return (
    <div className="Contacts">
      <div className="title-wrap">
        <div className="title">
          <div className="title-block">
            <h4>{t('nav.contacts')}</h4>
          </div>
        </div>
      </div>
      <div className="contact-block-wrap">
        <div className="contact-block">
          <ContactInfo />
          <ContactForm />
        </div>
      </div>
    </div>
  )
}
