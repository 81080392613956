import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { inject, observer } from 'mobx-react'

const ContactForm = ({ modal = false, sendEmail, isSending }) => {
  const { t } = useTranslation()

  const schema = yup.object().shape({
    name: yup.string().trim().required('Field is required!'),
    lastName: yup.string().trim(),
    phone: yup.string().trim().required('Field is required!'),
    email: yup
      .string()
      .trim()
      .required('Field is required!')
      .email('Email is not valid!'),
    company: yup.string().trim(),
  })

  const initialState = {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    company: '',
    message: '',
  }

  return (
    <div className="contact-form" onClick={(e) => e.stopPropagation()}>
      <Formik
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={(values) => sendEmail({ ...values })}
      >
        {({ dirty, errors, touched }) => (
          <Form>
            <h5>{t('btn.contact')}</h5>
            {!modal && <p>{t('page.contact_desc')}</p>}
            <div className="inputs">
              <div className="input-wrap">
                <div className="input-item">
                  <Field type="text" name="name" placeholder={t('form.name')} />
                  {touched.name && errors.name && (
                    <span className="error">{errors.name}</span>
                  )}
                </div>
                <div className="input-item">
                  <Field
                    type="text"
                    name="lastName"
                    placeholder={t('form.last_name')}
                  />
                </div>
                <div className="input-item">
                  <Field
                    type="text"
                    name="email"
                    placeholder={t('form.email')}
                  />
                  {touched.email && errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="input-item">
                  <Field
                    type="text"
                    name="phone"
                    placeholder={t('form.phone')}
                  />
                  {touched.phone && errors.phone && (
                    <span className="error">{errors.phone}</span>
                  )}
                </div>
                <div className="input-item stretched">
                  <Field
                    type="text"
                    name="company"
                    placeholder={t('form.company')}
                  />
                </div>
              </div>
              <p>{t('form.message')}</p>
              <div className="input_message">
                <Field type="text" name="message" as="textarea" />
              </div>
              <div className="btn-contact">
                <button
                  className={`contact-submit ${
                    !dirty || !!Object.keys(errors).length || isSending
                      ? 'disabled'
                      : ''
                  }`}
                  type="submit"
                  disabled={!dirty || !!Object.keys(errors).length || isSending}
                >
                  {t('btn.send')}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const withData = inject((stores) => ({
  sendEmail: stores.ContactStore.sendEmail,
  isSending: stores.ContactStore.isSending,
}))

export default withData(observer(ContactForm))
