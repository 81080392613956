import React from 'react'

import './NormalModal.scss'

const Modal = ({ active, setActive, children }) => {
  return (
    <div
      className={`layout ${active ? 'active' : ''}`}
      onClick={() => setActive(false)}
    >
      {children}
    </div>
  )
}

export default Modal
