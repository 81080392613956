import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../../NormalModal'
import ContactForm from '../../ContactComponents/ContactForm'

const TitleBlock = () => {
  const [modalActive, setModalActive] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="first-block-wrap">
      <div className="first-block">
        <h1>Anvarix</h1>
        <p>{t('page.home.description')}</p>
        <button className="contact-btn" onClick={() => setModalActive(true)}>
          {t('btn.contact')}
        </button>
        <Modal active={modalActive} setActive={setModalActive}>
          <ContactForm modal={true} />
        </Modal>
      </div>
    </div>
  )
}

export default TitleBlock
