export const fallbackLng = 'en'
export const selectionLng = [
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'es',
    label: 'ES',
  },
  {
    value: 'de',
    label: 'DE',
  },
]

export const availableLanguages = selectionLng.map((v) => v.value)
